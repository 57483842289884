import styled from 'styled-components'
import mq from 'styles/mq'
import { Column } from '../styled'
import theme from 'styles/theme'
import { ExternalWrapper } from 'components/links/styled'

export const Section = styled.section<{ dark?: boolean }>`
  padding: 3rem;
  background: ${({ dark }) => (dark ? theme.color.background.gray : '#fff')};

  ${mq.medium} {
    padding: 2rem 5rem;
  }
`

export const ImageColumn = styled(Column as any)`
  margin-top: 0;
  order: 2;

  ${mq.medium} {
    order: 1;
  }
`

export const ContentWrap = styled.div`
  ${ExternalWrapper} {
    margin-top: 2rem;
  }
`

export const ImageWrap = styled.div`
  width: 20rem;
  margin: 4rem auto;

  ${mq.small} {
    width: 32rem;
  }

  ${mq.medium} {
    width: 32rem;
    float: right;
  }

  img {
    width: 100%;
  }
`

export const ContentColumn = styled(Column as any)`
  display: flex;

  ${mq.medium} {
    order: 2;
  }
`
