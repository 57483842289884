import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import Container from 'components/container'
import Layout from 'components/layout'
import SEO from 'components/seo'
import Institutions from 'components/institutions'
import CLIAIcon from 'components/icons/clia'
import HouseIcon from 'components/icons/house'
import StethoscopeIcon from 'components/icons/stethoscope'
import LockIcon from 'components/icons/lock'
import PaperSection from 'containers/clinicians/paper-section'
import PeopleSlider from 'components/people-slider'
import Tiles from './tiles'
import {
  Header,
  Headline,
  Section,
  SectionTitle,
  Paragraph,
  Img,
  Intro,
  ImgContainer,
  IconContainer,
  Triad,
  TriadItem,
  ItemIcon,
  ItemTitle,
  ItemParagraph,
  TriadCTA,
} from './styled'
import { ButtonLink } from 'components/button'
import urls from 'common/urls'
import ReferencesFootnote from 'components/references-footnote'

type QueryResult = {
  hero: FluidImage
  advisors: { nodes: Member[] }
}

const query = graphql`
  query {
    hero: file(
      relativePath: {
        eq: "kit-box-open-peer-reviewed-and-clinically-validated.png"
      }
    ) {
      childImageSharp {
        fluid(quality: 75, maxWidth: 528) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    advisors: allTeamJson(filter: { is_advisor: { in: [true] } }) {
      nodes {
        ...teamFields
      }
    }
  }
`

const OurScience = () => {
  const data = useStaticQuery<QueryResult>(query)

  const references = [
    {
      symbol: '§',
      content:
        'Samplaski et al. Development and validation of a novel mail-in semen analysis system and the correlation between one hour and delayed semen analysis testing; Fertil Steril. 2021;115(4):922-929',
    },
  ]

  return (
    <Layout>
      <SEO title="Our Science" />
      <Header>
        <Container>
          <Headline>
            Our test is the only peer-reviewed, clinically valid, mail-in semen
            analysis.
          </Headline>
        </Container>
      </Header>

      <Section hasBackground>
        <Container>
          <Intro>
            <div>
              <SectionTitle>
                But wait… won&apos;t my sperm die before arriving at your lab?
              </SectionTitle>

              <Paragraph>
                Good question. Our method of providing mail-in semen analysis
                is&nbsp;
                <a href="https://www.meetfellow.com/blog/fellow%E2%80%99s-accuracy-study-published-in-peer-reviewed-journal/">
                  published and peer-reviewed
                </a>
                &nbsp;in one of the leading scientific journals on reproductive
                health, <em>Fertility & Sterility</em>. This means that
                we&apos;ve demonstrated that we can provide results on par with
                traditional 1-hour semen analysis when analyzing samples
                received within 52 hours of when they were produced.
              </Paragraph>
              <Paragraph>
                How does it work? <span translate="no">Fellow</span> Semen
                Analysis kits include a preservation solution designed to
                stabilize your sample and a gel pack that helps maintain a
                moderate temperature during transit. Overnight shipping is
                included so your sample gets to us as quickly as possible.
                Despite these measures, semen does still degrade over time.
                That&apos;s why we developed and validated an algorithm that
                predicts degradation, allowing us to calculate results that are
                comparable to those provided by testing a fresh specimen.
              </Paragraph>
              <Paragraph>
                If you&apos;re using the <span translate="no">Fellow</span>{' '}
                Vasectomy Test, we look for the presence of sperm—<em>any</em>{' '}
                sperm, living or dead—so degradation isn&apos;t an issue. As
                long as your sample arrives within 10 days, we can analyze it,
                and if we find sperm you should test again.
              </Paragraph>
            </div>
            <ImgContainer>
              {/* @ts-expect-error */}
              <Img fluid={data.hero.childImageSharp.fluid} />
            </ImgContainer>
          </Intro>
        </Container>
      </Section>

      <Section>
        <Container>
          <SectionTitle>
            Developed with leading doctors to give you clinically valid results.
          </SectionTitle>
          <Paragraph>
            When it comes to your health and fertility results, “close enough”
            doesn&apos;t cut it. We&apos;ve partnered with top reproductive
            health doctors to help ensure that our tests are on par with semen
            analyses performed within 1 hour of ejaculation.<sup>§</sup>
          </Paragraph>
          <PeopleSlider items={data.advisors.nodes} />
        </Container>
      </Section>
      <PaperSection />
      <Institutions
        title="Our test is recommended by doctors at top institutions."
        large
      />

      <Section>
        <Container>
          <Intro>
            <div>
              <SectionTitle>
                We own and operate our own CLIA-certified laboratory, so we
                operate at a standard you can trust.
              </SectionTitle>
              <Paragraph>
                CLIA (pronounced “KLEE-uh”) stands for Clinical Laboratory
                Improvement Amendments, which are federal regulations that
                describe the standards of quality and compliance for
                laboratories operating in the United States. Being
                CLIA-certified means that we are recognized by the federal
                government for meeting quality regulations for running tests
                that are used to help doctors and patients make health
                decisions.&nbsp;
                <a
                  href="https://www.fda.gov/medical-devices/ivd-regulatory-assistance/clinical-laboratory-improvement-amendments-clia#:~:text=The%20Clinical%20Laboratory%20Improvement%20Amendments,human%20samples%20for%20diagnostic%20testing"
                  target="_blank"
                  rel="noopenner noreferrer"
                >
                  Read more about CLIA
                </a>
                .
              </Paragraph>
            </div>
            <IconContainer>
              <CLIAIcon />
            </IconContainer>
          </Intro>
        </Container>
      </Section>

      <Tiles />

      <Section hasBackground>
        <Container>
          <Triad>
            <TriadItem>
              <ItemIcon>
                <HouseIcon />
              </ItemIcon>
              <ItemTitle>
                The only clinically validated semen analysis that lets you
                produce your sample at home.
              </ItemTitle>
              <ItemParagraph>
                Our CLIA-certified lab and clinically validated process mean we
                can reliably analyze your semen within 52 hours of sample
                production.
              </ItemParagraph>
            </TriadItem>
            <TriadItem>
              <ItemIcon>
                <StethoscopeIcon />
              </ItemIcon>
              <ItemTitle>
                Top doctors helped create our test, and trust us more than
                competitors.
              </ItemTitle>
              <ItemParagraph>
                Top fertility doctors at UCSF, Stanford, and USC helped validate
                our unique technology and approach.
              </ItemParagraph>
            </TriadItem>
            <TriadItem>
              <ItemIcon>
                <LockIcon />
              </ItemIcon>
              <ItemTitle>Your information is secure.</ItemTitle>
              <ItemParagraph>
                Samples are analyzed in our secure, state-of-the art lab, and
                your data is stored in an encrypted database.
              </ItemParagraph>
            </TriadItem>
          </Triad>
          <TriadCTA>
            <ButtonLink to={urls.shop}>Shop</ButtonLink>
          </TriadCTA>
        </Container>
      </Section>
      <ReferencesFootnote references={references} />
    </Layout>
  )
}

export default OurScience
