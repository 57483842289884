import styled, { css } from 'styled-components'
import ImgBase from 'gatsby-image'

import theme from 'styles/theme'
import { paragraph, heading } from 'styles/typography'
import mq from 'styles/mq'

export const Box = styled.div`
  display: grid;
  grid-template-columns: auto;
  align-items: start;
  margin: 7rem 0;

  ${mq.medium} {
    grid-template-columns: 26.9rem 1fr;
    grid-gap: 5rem;
    padding: 4rem 5rem;
    border: 1px solid ${theme.color.border.light};
  }

  ${mq.large} {
    grid-gap: 7rem;
    padding: 6.4rem 7.2rem;
  }
`

export const DoctorText = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2.4rem;
  align-self: center;

  ${mq.medium} {
    margin-top: 0;
  }
`

export const Bio = styled.p`
  ${paragraph.normal}
  margin-top: 0;
  order: 1;

  ${mq.medium} {
    order: 0;
  }
`

export const Name = styled.p`
  ${heading.xs};
  margin: 0;

  ${mq.medium} {
    margin: 1.6rem 0;
  }
`

export const JobPosition = styled.p`
  ${paragraph.extraSmall}
  opacity: 0.7;

  ${mq.medium} {
    ${paragraph.small}
    opacity: 1;
  }
`

export const SliderContainer = styled.div`
  margin-bottom: -2rem;

  ${mq.medium} {
    padding: 4rem 4rem 0;
    margin-bottom: -5rem;
  }

  .slick-slider {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
`

export const Tile = styled.div`
  .gatsby-image-wrapper {
    margin-bottom: 2rem;
  }

  ${mq.medium} {
    padding: 0 3.2rem;

    .gatsby-image-wrapper {
      margin-bottom: 0;
    }
  }
`

export const ImgContainer = styled.div`
  position: relative;

  &:before {
    content: '';
    display: block;
    padding-top: 120%;
  }
`

export const Img = styled(ImgBase)<{ small?: boolean; medium?: boolean }>`
  position: absolute !important;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  ${props =>
    props.medium === false &&
    css`
      ${mq.medium} {
        display: none;
      }

      ${mq.large} {
        display: block;
      }
    `}

  ${props =>
    props.medium &&
    css`
      display: none;

      ${mq.medium} {
        display: block;
      }

      ${mq.large} {
        display: none;
      }
    `}
`

export const StaticImg = Img.withComponent('img')

export const MemberContainer = styled.div`
  width: 100%;

  ${mq.medium} {
    width: 50%;
    display: inline-block;
  }

  ${mq.large} {
    width: 33.333%;
    display: inline-block;
  }
`
