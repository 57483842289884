import React, { FC, SVGProps } from 'react'
import Svg from 'components/svg'

const HouseIcon: FC<SVGProps<SVGSVGElement>> = props => (
  <Svg {...props} viewBox="0 0 48 48">
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M24 5l5.8334 5.6132v-3.391H34.5v7.8815L42.1865 22.5h-4.1866v19.389h-28V22.5H5.8135L24 5zm-.1831 31.034V25.2852s2.1292-5.375 5.6562-2.2392C33 26.1818 23.8169 36.034 23.8169 36.034zm-.0005 0V25.2852s-2.3164-5.3515-5.6561-2.2393c-3.3398 3.1122 5.6561 12.9881 5.6561 12.9881z"
      clipRule="evenodd"
    />
  </Svg>
)

export default HouseIcon
