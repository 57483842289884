import React, { useRef, useEffect } from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import { ScreenSize } from 'styles/mq'
import { SectionTitle, Paragraph } from '../styled'
import {
  Container,
  TextContainer,
  VideoContainer,
  ImgContainer,
  Img,
} from './styled'

type QueryResult = {
  lab: FluidImage
}

const query = graphql`
  query {
    lab: file(relativePath: { eq: "lab.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1440) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const Tiles = () => {
  const data = useStaticQuery<QueryResult>(query)
  const videoRef = useRef<HTMLVideoElement>(null)

  useEffect(() => {
    const videoObserver = new IntersectionObserver(entries => {
      if (videoRef.current) {
        if (entries[0].isIntersecting) {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          videoRef.current.play()
        } else {
          videoRef.current.pause()
        }
      }
    })

    if (videoRef && videoRef.current) {
      videoObserver.observe(videoRef.current)
    }
  })

  return (
    <Container>
      <TextContainer>
        <SectionTitle>
          We strive to improve fertility outcomes through research.
        </SectionTitle>
        <Paragraph>
          Steps from UCSF, our secure biolab is equipped with cutting-edge
          technology built exclusively for sperm analysis.
          In partnership with leading experts and universities, we’re able
          to pioneer research in male reproductive sciences.
        </Paragraph>
      </TextContainer>

      <ImgContainer>
        <Img
          // @ts-expect-error
          fluid={{
            ...data.lab.childImageSharp.fluid,
            sizes: `(max-width: ${ScreenSize.SM}px) 100vw, (max-width: ${ScreenSize.MD}px) 40vw, 50vw`,
          }}
        />
      </ImgContainer>

      <VideoContainer>
        <video ref={videoRef} preload="auto" playsInline loop muted>
          <source
            src="https://s3-us-west-2.amazonaws.com/production-assets-api.meetfellow.com/static/sperm-lab-video.mp4"
            type="video/mp4"
          />
        </video>
      </VideoContainer>
    </Container>
  )
}

export default Tiles
