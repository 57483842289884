import React, { FC, SVGProps } from 'react'
import Svg from 'components/svg'

const StethoscopeIcon: FC<SVGProps<SVGSVGElement>> = props => (
  <Svg viewBox="0 0 80 80" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12.994 10.5808c-2.9896 0-5.4131 2.4236-5.4131 5.4132v18.0069c0 4.9326 3.9986 8.9312 8.9311 8.9312h4.7559c4.9325 0 8.9312-3.9986 8.9312-8.9312V16.3871c0-3.2067-2.5996-5.8063-5.8062-5.8063-1.8173 0-3.2905-1.4731-3.2905-3.2904C21.1024 5.4732 22.5756 4 24.3929 4c6.8411 0 12.387 5.5459 12.387 12.3871v17.6138c0 8.5671-6.945 15.512-15.512 15.512H16.512C7.945 49.5129 1 42.568 1 34.0009V15.994C1 9.3699 6.3699 4 12.994 4c1.8172 0 3.2904 1.4732 3.2904 3.2904 0 1.8173-1.4732 3.2904-3.2904 3.2904z"
      clipRule="evenodd"
    />
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M58.2641 37.5678c-5.2685 0-9.5395 4.271-9.5395 9.5395v12.1662c0 9.2695-7.5144 16.7839-16.7839 16.7839-9.2695 0-16.784-7.5144-16.784-16.7839V45.78h6.5809v13.4935c0 5.635 4.5681 10.2031 10.2031 10.2031 5.635 0 10.203-4.5681 10.203-10.2031V47.1073c0-8.903 7.2173-16.1204 16.1204-16.1204 8.903 0 16.1203 7.2174 16.1203 16.1204v14.1571h-6.5809V47.1073c0-5.2685-4.2709-9.5395-9.5394-9.5395z"
      clipRule="evenodd"
    />
    <circle cx="71.0943" cy="64.3612" r="8.4058" fill="currentColor" />
    <circle cx="71.094" cy="64.3613" r="3.9817" fill="#fff" />
  </Svg>
)

export default StethoscopeIcon
