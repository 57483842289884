import React, { FC, SVGProps } from 'react'
import Svg from 'components/svg'

const CLIAIcon: FC<SVGProps<SVGSVGElement>> = props => (
  <Svg viewBox="0 0 255 257" {...props}>
    <g fill="currentColor" clipPath="url(#a)">
      <path
        fillRule="evenodd"
        d="m127.5 0-4.61 14.87h-14.88l12.07 9.22-4.42 14.27-.19.61 12.03-9.18 12.03 9.18-4.6-14.87L147 14.88h-14.88L127.52.01 127.5 0ZM0 142.66h14.88l4.61-14.87 4.61 14.87h14.88l-12.06 9.22 4.61 14.87-12.03-9.22-12.03 9.22.19-.65 4.38-14.23-12.03-9.22-.01.01ZM230.91 142.66h-14.88l12.07 9.22-4.41 14.23-.19.65 12.03-9.22 12.07 9.22-4.6-14.87 12.03-9.22h-14.88l-4.61-14.87-4.6 14.87-.03-.01ZM76.5 42.04l12.03-9.18H73.65l-4.57-14.87-4.61 14.87H49.59l12.03 9.18-4.41 14.27-.19.65 12.06-9.22 12.03 9.22-4.61-14.91v-.01ZM173.9 56.95l.19-.65 4.41-14.27-12.03-9.18h14.88l4.57-14.87 4.6 14.87h14.88l-12.03 9.18 4.61 14.91-12.07-9.22-12.03 9.22.02.01ZM18.27 102.36l-.19.61 12.03-9.18 12.03 9.18-4.57-14.87 12.03-9.18H34.72l-4.61-14.91-4.61 14.91H10.62l12.06 9.18-4.41 14.27v-.01ZM212.87 102.97l.19-.61 4.42-14.27-12.07-9.18h14.88L224.9 64l4.6 14.91h14.88l-12.03 9.18 4.57 14.87-12.03-9.18-12.03 9.18.01.01ZM86.28 151.76c4.39.13 8.7-1.21 12.23-3.81 3.53-2.6 6.09-6.31 7.26-10.53H95.46c-.79 1.81-2.09 3.35-3.73 4.44a10.335 10.335 0 0 1-5.55 1.71c-1.4 0-2.78-.3-4.06-.85a10.458 10.458 0 0 1-5.64-5.84c-.5-1.3-.74-2.69-.69-4.08-.05-1.38.19-2.76.68-4.05.5-1.29 1.25-2.47 2.21-3.46.96-1 2.11-1.79 3.39-2.33 1.27-.54 2.65-.82 4.03-.83 2.03-.01 4.01.59 5.68 1.73a9.934 9.934 0 0 1 3.68 4.64h10.31a19.35 19.35 0 0 0-7.22-10.74c-3.57-2.65-7.94-4-12.38-3.83-2.5 0-4.97.49-7.28 1.44-2.31.95-4.41 2.35-6.17 4.11a18.974 18.974 0 0 0-4.13 6.15c-.96 2.3-1.45 4.77-1.45 7.26s.49 4.96 1.45 7.26 2.36 4.39 4.13 6.15c1.77 1.76 3.86 3.16 6.17 4.11 2.31.95 4.78 1.44 7.28 1.44l.11-.11v.02ZM131.76 143H120.3v-28.27h-9.86v36.46h21.31v-8.2l.01.01Z"
        clipRule="evenodd"
      />
      <path d="M146.04 114.73h-9.86v36.46h9.86v-36.46Z" />
      <path
        fillRule="evenodd"
        d="M174.28 114.73h-8.53l-15.22 36.46h10.69l1.75-5.27h13.51l1.83 5.27h10.81l-14.84-36.46Zm-8.87 23.79 4.15-12.71 4.34 12.71h-8.49Z"
        clipRule="evenodd"
      />
      <path d="M30.31 211.1c-4.4-5.29-3.86-13.03 1.48-17.47 5.33-4.44 12.96-3.59 17.36 1.7 2.74 3.29 3.64 7.46 2.4 11.58-.39 1.33-1.04 2.65-1.95 3.71l-4.05-4.87c1.27-2.27 1.21-5-.49-7.04-2.46-2.95-6.56-2.78-9.36-.44-2.8 2.33-3.81 6.42-1.33 9.4 1.7 2.04 4.25 2.5 6.72 1.82l4.05 4.87c-5.18 2.53-11.15 1.17-14.81-3.23l-.02-.03ZM50.39 230.02l13.55-19.42 10.79 7.53-3.04 4.36-6.04-4.21-2.11 3.02 5.85 4.08-3.04 4.36-5.85-4.08-2.33 3.33 6.04 4.21-3.04 4.36-10.79-7.53.01-.01ZM85.72 249.2l-2.38-9.1-.06-.02-2.73 7.14-5.41-2.07 8.46-22.12 7 2.67c2.33.89 4.88 1.93 6.21 4.19 1.26 2.16 1.29 4.78.41 7.08-1.3 3.41-4.45 5.63-8.16 4.86l3.13 9.83-6.46-2.47-.01.01Zm3.45-18.35-2.06-.79-2.35 6.16 2 .77c2.12.81 4.05.52 4.92-1.75.83-2.18-.56-3.64-2.5-4.38l-.01-.01ZM116.95 237.58l-2.42 18.21-6-.8 2.42-18.21-4.63-.62.7-5.27 15.23 2.02-.7 5.27-4.6-.61v.01ZM132.95 256.46l-1.38-23.64 6.04-.35 1.38 23.64-6.04.35ZM155.57 234.74l1.01 3.9 6.66-1.72 1.33 5.14-6.66 1.72 2.25 8.74-5.61 1.44-5.91-22.93 12.52-3.22 1.32 5.14-6.91 1.78v.01ZM180.73 245.36l-10.23-21.35 5.45-2.61 10.23 21.35-5.45 2.61ZM199.82 234.85l-14.21-18.95 10.52-7.89 3.19 4.25-5.89 4.42 2.21 2.94 5.71-4.28 3.19 4.25-5.71 4.28 2.44 3.25 5.89-4.42 3.19 4.25-10.52 7.89-.01.01ZM227.08 209.8l-5.17 6.48-18.52-14.76 4.89-6.13c2.67-3.35 5.4-5.64 9.97-5.43 2.63.13 5.06 1.25 7.11 2.88 6.08 4.85 6.54 10.9 1.71 16.95l.01.01Zm-14.12-11.75-1.64 2.05 10.21 8.14 1.62-2.03c2.57-3.23 1.53-6.19-1.47-8.58-2.95-2.35-6.21-2.74-8.72.42Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="none" d="M0 0h255v256.46H0z" />
      </clipPath>
    </defs>
  </Svg>
)

export default CLIAIcon
