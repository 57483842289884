import React, { FC, SVGProps } from 'react'
import Svg from 'components/svg'

const LockIcon: FC<SVGProps<SVGSVGElement>> = props => (
  <Svg viewBox="0 0 80 80" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M31.6887 29.6241C34.253 28.5771 37.0591 28 40 28c2.9379 0 5.7413.5759 8.3035 1.6209v-5.9557c0-4.637-3.7193-8.396-8.3074-8.396-4.5881 0-8.3074 3.759-8.3074 8.396v5.9589zm-7.1926 4.7673C20.4841 38.3767 18 43.8983 18 50c0 12.1503 9.8497 22 22 22s22-9.8497 22-22c0-6.1057-2.4873-11.6305-6.5039-15.6163V23.6652C55.4961 15.0135 48.5565 8 39.9961 8s-15.5 7.0136-15.5 15.6652v10.7262zM42 54.1756c2.3304-.8602 4-3.1812 4-5.9095C46 44.8054 43.3137 42 40 42s-6 2.8054-6 6.2661c0 2.7283 1.6696 5.0493 4 5.9095v5.2234c0 1.1045.8954 2 2 2s2-.8955 2-2v-5.2234z"
      clipRule="evenodd"
    />
  </Svg>
)

export default LockIcon
