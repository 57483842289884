import React from 'react'
import Img, { FluidObject } from 'gatsby-image'
import { useStaticQuery, graphql } from 'gatsby'
import { ExternalLink } from 'components/links'
import {
  Section,
  SectionParagraph,
  Center,
  Row,
  SectionTitleSmall,
} from '../styled'
import { ImageColumn, ImageWrap, ContentColumn, ContentWrap } from './styled'
import { ScreenSize } from '../../../styles/mq'

type FluidImage = {
  childImageSharp: {
    fluid: FluidObject
  }
}

type QueryResult = {
  publication: FluidImage
  review: FluidImage
}

const query = graphql`
  query {
    publication: file(relativePath: { eq: "publication.png" }) {
      childImageSharp {
        fluid(quality: 75, maxWidth: 528) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    review: file(relativePath: { eq: "review.png" }) {
      childImageSharp {
        fluid(quality: 75, maxWidth: 528) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const PaperSection = () => {
  const data = useStaticQuery<QueryResult>(query)

  return (
    <Section>
      <Center>
        <Row>
          <ImageColumn>
            <ImageWrap>
              <Img
                fluid={{
                  ...data.publication.childImageSharp.fluid,
                  sizes: `(min-width: ${ScreenSize.LG}px) 500px, (min-width: ${ScreenSize.MD}px) 50vw, 100vw`,
                }}
                alt="An image of the publication about the Fellow test"
              />
            </ImageWrap>
          </ImageColumn>
          <ContentColumn>
            <ContentWrap>
              <SectionTitleSmall>
                Clinically validated accuracy
              </SectionTitleSmall>
              <SectionParagraph>
                <span translate="no">Fellow&apos;s&nbsp;</span>validation study, published in the peer-reviewed
                journal
                <em> Fertility and Sterility</em>, demonstrated the accuracy of
                the<span translate="no">&nbsp;Fellow&nbsp;</span>test. Our mail-in semen analysis testing system has a
                strong degree of correlation between 1-hour and delayed SA
                testing. This test may be used in clinical practice to evaluate
                semen quality for fertility evaluations.
              </SectionParagraph>
              <ExternalLink
                to="https://www.sciencedirect.com/science/article/abs/pii/S0015028220326194"
                inverse
              >
                Read the publication
              </ExternalLink>
            </ContentWrap>
          </ContentColumn>
        </Row>
        <Row>
          <ImageColumn>
            <ImageWrap>
              <Img
                fluid={{
                  ...data.review.childImageSharp.fluid,
                  sizes: `(min-width: ${ScreenSize.LG}px) 500px, (min-width: ${ScreenSize.MD}px) 50vw, 100vw`,
                }}
                alt="an image of the peer review of the Fertility and Sterility publication"
              />
            </ImageWrap>
          </ImageColumn>
          <ContentColumn>
            <ContentWrap>
              <SectionTitleSmall>Independently reviewed</SectionTitleSmall>
              <SectionParagraph>
                <span>
                  An independent review of our validation study noted the
                  benefits of<span translate="no">&nbsp;Fellow&nbsp;</span>&apos;s accurate mail-in test:
                </span>
                <span>
                  &quot;The ability to perform at-home semen analyses testing
                  has numerous clinical and patient implications, including
                  reducing patient anxiety, improving convenience, and
                  potentially the ability to see higher levels of patient
                  compliance&quot;
                </span>
                <small>
                  —Thomas A. Masterson, M.D. &amp; Premal Patel, M.D.
                </small>
              </SectionParagraph>
              <ExternalLink
                to="https://www.fertstert.org/article/S0015-0282(21)00087-X/fulltext"
                inverse
              >
                Read the publication
              </ExternalLink>
            </ContentWrap>
          </ContentColumn>
        </Row>
      </Center>
    </Section>
  )
}

export default PaperSection
