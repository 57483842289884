import styled, { css } from 'styled-components'
import ImgBase from 'gatsby-image'

import { heading, paragraph } from 'styles/typography'
import theme from 'styles/theme'
import mq from 'styles/mq'

export const Header = styled.header`
  padding: 6rem 0 0;

  ${mq.medium} {
    padding: 15rem 0 0;
  }

  background-color: ${theme.color.background.light};
`

export const Headline = styled.h1`
  ${heading.xxl};
  margin-bottom: 0;
  text-align: center;
`

export const Section = styled.section<{ hasBackground?: boolean }>`
  position: relative;
  padding: 8rem 0;

  ${mq.medium} {
    padding: 12rem 0;
  }

  ${props =>
    props.hasBackground &&
    css`
      background-color: white;
    `}
`

export const SectionTitle = styled.h2<{ indent?: boolean; centered?: boolean }>`
  ${heading.xl};
  max-width: 77rem;

  ${props =>
    props.indent &&
    css`
      text-indent: -0.5em;
    `}
  ${props =>
    props.centered &&
    css`
      text-align: center;
      max-width: 90rem;
      margin: auto;
    `}
`

export const Paragraph = styled.p`
  ${paragraph.small};
  max-width: 77rem;
`

export const Img = styled(ImgBase)``

export const Intro = styled.div`
  display: grid;
  grid-template-columns: auto;
  grid-gap: 6rem;

  ${mq.medium} {
    align-items: center;
    grid-template-columns: auto;
    grid-gap: 9rem;
  }

  ${mq.large} {
    grid-template-columns: 1fr 1fr;
  }
`

export const ImgContainer = styled.div`
  position: relative;
  margin: 0 -3rem;
  grid-row: 1;

  ${mq.medium} {
    margin: 0;
  }

  ${mq.large} {
    grid-row: auto;
  }
`

export const IconContainer = styled.div`
  position: relative;
  margin: 0 -3rem;
  grid-row: 1;
  color: ${theme.color.accent.secondary};
  font-size: 18rem;

  svg {
    display: block;
    margin: auto;
  }

  ${mq.medium} {
    margin: 0;
    font-size: 24rem;
  }

  ${mq.large} {
    font-size: 46rem;
    grid-row: auto;
  }
`

export const Triad = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;

  ${mq.medium} {
    flex-direction: row;
    justify-content: space-between;
  }
`

export const TriadItem = styled.div`
  display: flex;
  flex-direction: column;

  ${mq.medium} {
    max-width: 36rem;
  }
`

export const ItemIcon = styled.div`
  color: ${theme.color.accent.secondary};
  font-size: 10rem;
`

export const ItemTitle = styled.div`
  ${heading.s};
  line-height: 2.4rem;
  padding: 0 1rem 2rem;
`

export const ItemParagraph = styled.div`
  ${paragraph.small};
  padding: 0 1rem 4rem;
`

export const TriadCTA = styled.div`
  margin: auto;
  padding-top: 4rem;
  text-align: center;
`
