import React, { FC } from 'react'
import Img from 'gatsby-image'

import { Name, JobPosition, Tile, ImageWrapper, StaticImage } from './styled'

type Props = {
  item: Member
  noSpacing?: boolean
}

const TeamMember: FC<Props> = ({ item, noSpacing }) => (
  <Tile noSpacing={noSpacing}>
    <a href={item.url} target="_blank" rel="noopener noreferrer">
      <ImageWrapper>
        {typeof item.thumbnail === 'string' ? (
          <StaticImage src={item.thumbnail} alt={item.fullName} />
        ) : (
          <Img fluid={item.thumbnail.childImageSharp.fluid} />
        )}
      </ImageWrapper>
      <div>
        <Name>{item.fullName}</Name>
        <JobPosition>{item.details}</JobPosition>
      </div>
    </a>
  </Tile>
)

export default TeamMember
