import styled, { css } from 'styled-components'
import theme from 'styles/theme'
import { heading, paragraph } from 'styles/typography'
import mq, { MaxWidth } from 'styles/mq'

export const Center = styled.div`
  max-width: ${MaxWidth / 10}rem;
  margin: 0 auto;

  ${mq.medium} {
    padding: 0 2rem;
  }

  ${mq.desktop} {
    padding: 0 8rem;
  }
`

export const FlexCenter = styled.div`
  max-width: 118rem;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Section = styled.section<{
  dark?: boolean
  gray?: boolean
  lightBlue?: boolean
}>`
  padding: 6rem 2.4rem;
  background: ${props => {
    switch (true) {
      case props.gray:
        return theme.color.background.gray
      case props.dark:
        return theme.color.background.bone
      case props.lightBlue:
        return theme.color.background.lightBlue
      default:
        return theme.color.background.light
    }
  }};

  ${mq.medium} {
    padding: 8rem 2.4rem;
  }
`

export const SectionEyebrow = styled.p<{ centered?: boolean }>`
  text-align: ${({ centered }) => (centered ? 'center' : 'left')};
  ${heading.eyebrow};
  color: ${theme.color.text.brown};
  margin-bottom: 2rem;
`

export const SectionTitle = styled.h2<{ centered?: boolean }>`
  ${heading.xl};
  text-align: ${({ centered }) => (centered ? 'center' : 'left')};
  max-width: 90rem;

  ${({ centered }) =>
    centered &&
    css`
      margin: 0 auto 2rem auto;
    `}
`

export const SectionTitleSmall = styled.h2`
  ${heading.m};
  line-height: 2.4rem;

  ${mq.medium} {
    ${heading.l};
  }
`

export const SectionSubTitle = styled.h3`
  ${paragraph.extraSmall};
  text-transform: uppercase;
  letter-spacing: 0.05em;
`

export const SectionParagraph = styled.p`
  ${paragraph.small};

  span {
    display: inline-block;
    margin-bottom: 1.6rem;

    &[translate='no'] {
      display: initial;
      margin-bottom: 0;
    }
  }

  small {
    font-size: 1.4rem;
  }
`

export const Row = styled.div`
  display: flex;
  flex-direction: column-reverse;
  margin-bottom: 4rem;

  ${mq.medium} {
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
  }
`

export const Column = styled.div<{
  size?: Array<string | number | null>
}>`
  width: ${props => (props.size && props.size[0] ? props.size[0] : '100%')};

  ${mq.medium} {
    width: ${props => (props.size && props.size[1] ? props.size[1] : '100%')};
  }
`

export const ButtonWrapper = styled.div`
  margin: 8rem auto 0;
  width: 24rem;
  text-align: center;
`
