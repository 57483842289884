import styled from 'styled-components'
import ImgBase from 'gatsby-image'

import mq from 'styles/mq'
import ContainerBase from 'components/container'
import theme from 'styles/theme'

export const Container = styled.section`
  display: grid;
  grid-template-columns: 100%;
  grid-template-areas: 'text' 'video' 'image';
  background-color: ${theme.color.background.light};

  ${mq.medium} {
    grid-template-columns: 1fr 40%;
    grid-template-rows: auto auto;
    grid-template-areas: 'text image' 'video image';
  }

  ${mq.large} {
    grid-template-columns: 1fr 1fr;
  }
`

export const TextContainer = styled(ContainerBase as any)`
  width: 100%;
  grid-area: text;
  margin: 8rem 0;

  ${mq.medium} {
    margin-left: auto;
    padding-right: 5rem !important;
  }

  ${mq.large} {
    margin: 10rem 0 10rem auto;
    padding-right: 14rem !important;
    max-width: 70rem;
  }
`

export const VideoContainer = styled.div`
  position: relative;
  width: 100%;
  grid-area: video;

  &:before {
    content: '';
    display: block;
    padding-top: ${1250 / 16}%;
  }

  video {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
`

export const Img = styled(ImgBase)`
  height: 100%;
  background: rgba(255, 255, 0, 0.2);
`

export const ImgContainer = styled.div`
  grid-area: image;
`
