import React, { useRef, FC } from 'react'
import Slider from 'react-slick'

import { ScreenSize } from 'styles/mq'
import {
  // Box,
  // Name,
  // JobPosition,
  SliderContainer,
  // DoctorText,
  // Img,
  // StaticImg,
  MemberContainer,
  // ImgContainer,
} from './styled'
import sliderNavSettings from 'components/carousel-navigation'
import TeamMember from 'components/team-member'

const sliderSettings = {
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  initialSlide: 0,
  draggable: false,
  arrows: false,
}

const thumbnailSliderSettings = {
  ...sliderSettings,
  ...sliderNavSettings,
  slidesToShow: 3,
  centerPadding: '0',
  focusOnSelect: true,
  centerMode: true,
  autoplay: true,
  autoplayInterval: 5000,
  draggable: true,
  arrows: true,
  responsive: [
    {
      breakpoint: ScreenSize.LG - 1,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: ScreenSize.MD - 1,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
}

const PeopleSlider: FC<{ items: readonly Member[] }> = ({ items }) => {
  const mainSlider = useRef<Slider>(null)
  const tilesSlider = useRef<Slider>(null)
  const slides = items.map(item => (
    <MemberContainer key={item.id}>
      <TeamMember item={item} />
    </MemberContainer>
  ))

  return (
    <SliderContainer>
      {items.length > 2 ? (
        <Slider
          {...thumbnailSliderSettings}
          ref={tilesSlider}
          beforeChange={(oldIndex, newIndex) =>
            mainSlider.current?.slickGoTo(newIndex)
          }
        >
          {slides}
        </Slider>
      ) : (
        slides
      )}
    </SliderContainer>
  )
}

export default PeopleSlider
