import styled, { css } from 'styled-components'
import { paragraph, heading } from 'styles/typography'
import mq from 'styles/mq'

export const Name = styled.p`
  ${heading.xs};
  margin: 2rem 0 1rem;
`

export const JobPosition = styled.p`
  ${paragraph.extraSmall};
  margin: 0;
  opacity: 0.7;

  ${mq.medium} {
    ${paragraph.small};
  }
`

export const Tile = styled.div<{ noSpacing?: boolean }>`
  .gatsby-image-wrapper {
    margin-bottom: 2rem;
  }

  ${mq.medium} {
    ${props =>
      !props.noSpacing &&
      css`
        padding: 0 3.2rem;
      `}

    .gatsby-image-wrapper {
      margin-bottom: 0;
    }
  }
`

export const Bio = styled.p`
  ${paragraph.small};
`

export const StaticImage = styled.img`
  display: block;
  width: 100%;
  object-fit: cover;
  object-position: center 30%;
`

export const ImageWrapper = styled.div`
  position: relative;
  min-height: 27rem;
  min-width: 27rem;

  &:before {
    content: '';
    display: block;
    padding-top: 56.4%;
  }

  .gatsby-image-wrapper,
  ${StaticImage} {
    position: absolute !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100% !important;
  }
`
